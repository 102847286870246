import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateEmail as firebaseUpdateEmail,
  updatePassword as firebaseUpdatePassword,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password).then(
      (data) => {
        //console.log("SignUp Successful");
      }
    );
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password).then((data) => {
      const userAttributes = JSON.parse(data.user.reloadUserInfo.customAttributes || "{}");
      //console.log("SignIn Successful", data);
      //console.log("Break-------------");
      //console.log(data.user.reloadUserInfo.customAttributes);
      if (userAttributes.role === "fulfillment_provider" || userAttributes.role === "admin") {
        // Allow login
        return data;
      } else {
        throw new Error("You are not authorized to log in.");
      }
    });
  }


  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return currentUser && firebaseUpdateEmail(currentUser, email);
  }

  function updatePassword(password) {
    return currentUser && firebaseUpdatePassword(currentUser, password);
  }

  function loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider).then((data) => {
      //console.log("Google SignIn", data);
    });
  }
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe; // This is the cleanup function
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    loginWithGoogle,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
