import React from "react";
import { Container } from "react-bootstrap";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./Dashboard";
import Login from "./login/Login";
import PrivateRoute from "./PrivateRoute";
import UpdateProfile from "./profile/UpdateProfile";
import Profile from "./profile/Profile";
import MyNavbar from "./navbar/Navbar";
import Orders from "./orders/Orders";

function App() {
  return (
    <Container className="d-flex flex-column align-items-center" style={{ minHeight: "100vh" }}>
      <Router>
        <AuthProvider>
          <ContentWithConditionalNavbar />
        </AuthProvider>
      </Router>
    </Container>
  );
}

function ContentWithConditionalNavbar() {
  const location = useLocation();
  
  // Specify the paths where you don't want the navbar to appear
  const noNavbarRoutes = ["/login", "/signup", "/forgot-password"];

  return (
    <>
      {!noNavbarRoutes.includes(location.pathname) && <MyNavbar />}
      <div className="w-100" style={{ maxWidth: "1200px", margin: "auto" }}>
        <Routes>
          {/* Unprotected routes */}
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="/login" element={<Login />} />
          {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}

          {/* Protected routes */}
          <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/update-profile" element={<PrivateRoute><UpdateProfile /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
          {/* <Route path="/printer-details" element={<PrivateRoute><PrinterDetails /></PrivateRoute>} /> */}
          {/* <Route path="/print-jobs" element={<PrivateRoute><PrintJobs /></PrivateRoute>} /> */}
        </Routes>
      </div>
    </>
  );
}

export default App;
