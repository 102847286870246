import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore";
// import { Buffer } from 'buffer';
// import {createUserWithEmailAndPassword} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export {app, auth, analytics, db}



// async function testCreateUser() {
//     const email = 'test@example.com'; // Change to a test email address
//     const password = 'testpassword'; // Change to a test password
  
//     try {
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//       console.log('User created successfully with UID:', userCredential.user.uid);
//     } catch (error) {
//       console.error('Error creating test user:', error);
//     }
//   }

// testCreateUser();
