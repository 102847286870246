import React, { useState } from "react";
import { Card, Button, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const history = useNavigate();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <>
      <Card className="mx-auto" style={{ maxWidth: "400px" }}>
        <Card.Body>
          <h2 className="text-center mb-4">Profile</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <strong>Email:</strong> {currentUser.email} <br/>
          {/* <strong>UID:</strong> {currentUser.uid} <br/>
          
          <strong>Access Token:</strong> {currentUser.accessToken} <br/>
          <strong>Refresh Token</strong> {currentUser.refreshToken} */}


          {/* <Link to="/update-profile" className="btn btn-outline-primary w-100 mt-4">
            Reset Password
          </Link> */}
         
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </>
  );
}

// import React, { useState } from 'react';
// import { Button, Form, InputGroup, Container, Row, Col } from 'react-bootstrap';

// function Profile() {
//     const [apiKey, setApiKey] = useState('');

//     const generateApiKey = () => {
//         // This function should be replaced with your actual API key generation logic.
//         // For demo purposes, we're just using a dummy string here.
//         setApiKey('12345-abcde-67890-fghij');
//     };

//     return (
//         <Container className="mt-5">
//             <Row className="justify-content-md-center">
//                 <Col xs={12} md={8}>
//                     <Form>
//                         <Form.Group controlId="formApiKey">
//                             <Form.Label>API Key</Form.Label>
//                             <InputGroup>
//                                 <Form.Control type="text" placeholder="Your API key will appear here" readOnly value={apiKey} />
//                                 <Button variant="primary" onClick={generateApiKey}>
//                                     Generate API Key
//                                 </Button>
//                             </InputGroup>
//                         </Form.Group>
//                     </Form>
//                 </Col>
//             </Row>
//         </Container>
//     );
// }

// export default Profile;

// import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Button, Form, Alert } from 'react-bootstrap';
// import { v4 as uuidv4 } from 'uuid';

// function Profile() {
//   const [apiKey, setApiKey] = useState('');
//   const [showSuccess, setShowSuccess] = useState(false);

//   const generateApiKey = () => {
//     const newKey = uuidv4(); // Replace with secure generation logic in production
//     setApiKey(newKey);
//     setShowSuccess(true);
//   };

//   return (
//     <div className="container">
//       <h1>API Key Generator</h1>
//       {showSuccess && (
//         <Alert variant="success">
//           Your API Key: {apiKey}
//           <Alert.Link href="#"> Instructions for using your API key</Alert.Link>
//         </Alert>
//       )}
//       <Form>
//         <Button variant="primary" onClick={generateApiKey}>
//           Generate API Key
//         </Button>
//       </Form>
//     </div>
//   );
// }

// export default Profile;

// import React, { useState } from "react";
// import { Button, Table, Container, Alert } from "react-bootstrap";

// const ApiKeysTable = () => {
//   const [apiKeys, setApiKeys] = useState([
//     {
//       name: "new key",
//       secret: "sk-...AfdW",
//       created: "Nov 19, 2023",
//       lastUsed: "Never",
//     },
//   ]);

//   // Function to simulate adding a new API key
//   const addApiKey = () => {
//     const newApiKey = {
//       name: `key ${apiKeys.length + 1}`,
//       secret: `sk-...${Math.random().toString(36).substr(2, 5)}`,
//       created: new Date().toLocaleDateString(),
//       lastUsed: "Never",
//     };
//     setApiKeys([...apiKeys, newApiKey]);
//   };

//   return (
//     <Container>
//       <h2 className="mt-3">API keys</h2>
//       <Alert variant="secondary">
//         Your secret API keys are listed below. Please note that we do not
//         display your secret API keys again after you generate them.
//         <br />
//         Do not share your API key with others, or expose it in the browser or
//         other client-side code. In order to protect the security of your
//         account, OpenAI may also automatically disable any API key that we've
//         found has leaked publicly.
//       </Alert>
//       <Button variant="primary" onClick={addApiKey} className="my-3">
//         + Create new secret key
//       </Button>
//       <Table striped bordered hover size="sm">
//         <thead>
//           <tr>
//             <th>Name</th>
//             <th>Secret Key</th>
//             <th>Created</th>
//             <th>Last Used</th>
//           </tr>
//         </thead>
//         <tbody>
//           {apiKeys.map((key, index) => (
//             <tr key={index}>
//               <td>{key.name}</td>
//               <td>{key.secret}</td>
//               <td>{key.created}</td>
//               <td>{key.lastUsed}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </Container>
//   );
// };

// export default ApiKeysTable;

// import ApiKeysGenerator from "../api_keys/ApiKeysTable"

// const Profile = () => {
 
//     return (
      
//       <ApiKeysGenerator />
      
    
//     )
// };
// export default Profile;
