import React from "react";
// import OrderDisplay from "./orders/Orders"
import OrderDash from "./orders/Orders"


export default function Dashboard() {
  return (
    <>
        <OrderDash/>
    </>
  );
}
