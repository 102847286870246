import React, { } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/White.png";

function MyNavbar() {

  return (
    <Navbar expand="lg" bg="dark" variant="dark" fixed="top">
      <Container fluid style={{ maxWidth: '1200px' }}>
        <Navbar.Brand href="/" style={{ marginLeft: '0px' }}>
          <img
            src={logo}
            alt="Pairbo Logo"
            style={{ height: '30px' }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            navbarScroll
          >

          </Nav>
          <Nav className="ms-auto my-2 my-lg-0">
            <Nav.Link href="/profile" style={{ marginRight: '0px',fontWeight: 'bold' }}>Profile</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;